import React, { useState } from 'react';
import '../App.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30vw",
  bgcolor: 'white',
  borderRadius:"20px",
  boxShadow: 24,
  p:4,
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyConent:"center",
  gap:"10px"
};

const socials = {
  email: { label: 'Email', icon: EmailIcon, className: 'modal-submit', urlbase:''},
  github: { label: 'GitHub', icon: GitHubIcon, className: 'modal-submit', urlbase: 'https://github.com/'},
  twitter: { label: 'Twitter', icon: TwitterIcon, className: 'modal-submit', urlbase: 'https://twitter.com/' },
  kmsuite: { label: 'KM Suite', icon: LanguageIcon, className: 'modal-submit', urlbase: 'https://kmsuite.com/' }, // Replace LanguageIcon with the actual icon when available
  telegram: { label: 'Telegram', icon: TelegramIcon, className: 'modal-submit', urlbase: 'https://t.me/' },
  youtube: { label: 'YouTube', icon: YouTubeIcon, className: 'modal-submit', urlbase: 'https://www.youtube.com/' },
};

const Chat = ({openchat, handleClosechat, socialData}) => {
  const handleButtonClick = (socialValue) => {
    console.log(socialValue);
  };
  return (
    <Modal
      open={openchat}
      onClose={handleClosechat}
    >
      <Box sx={style}>
        <b>Chat</b>
        {socialData.map(({ key, value }) => {
          const social = socials[key];
          if (!social) return null;

          const IconComponent = social.icon;

          return (
            <a
              key={key}
              className='modal-submit'
              href={value.includes('http') ? value : social.urlbase + value}
              target="_blank"
              style={{height:"20px"}}
              onClick={()=>console.log(value)}
            >
              {IconComponent && <IconComponent />}
              {social.label}
            </a>
          );
        })}
      </Box>
    </Modal>
  );
};

export default Chat;