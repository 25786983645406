import React, { useState } from 'react';
import '../App.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Accordion from '../Universal/Accordion';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50vw",
    bgcolor: 'white',
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "8px",
    '@media (max-width: 768px)': {
      width: '80vw',
    }
  };

function Firsttime({setView, openfirsttime, handleClosefirsttime, setOpenverifyuser, openverifyuser, handleCloseverifyuser, openaddsolution, setOpenaddsolution, handleOpenverifyuser, handleOpenaddsolution, handleCloseaddsolution}) {
const chooseoption = (option) =>{
    if(option == "verify"){
        handleClosefirsttime()
        handleOpenverifyuser()
        localStorage.setItem("hasvisited", true)
    }
    if(option == "affiliate"){
        handleClosefirsttime()
        setView("addsolution")
        localStorage.setItem("hasvisited", true)
    }
    if(option == "browse"){
        handleClosefirsttime()
    }
}
  return (
        <Modal
        open={openfirsttime}
        >
        <Box sx={style}>
        <b style={{alignSelf:"flex-start"}}>First time on eosden? You have a decision to make!</b>
        <p>You can become a <a href="https://youtu.be/5q-CDqI5-p0" target="_blank"><b>verified user</b></a>. This means you can:</p>
        <li>Rate solutions</li>
        <li>Review solutions</li>
        <li>Earn token rewards!</li>

        <p>You can become an <a href="https://youtu.be/rPh5-bxQqSs" target="_blank"><b>affiliate</b></a>. This means you can:</p>
        <li>Collaborate with other solutions</li>
        <li>Do everything that a verified user can, including rating and reviewing solutions</li>
        <li>Earn token rewards!</li>
        
        <br />
        <b style={{alignSelf:"flex-start"}}>Make a choice:</b>
        <div class="firsttime-choice-button-wrapper">
            <button className="firsttime-choice-button" style={{backgroundColor:"#00A3FF"}} onClick={() => chooseoption("verify")}>Become verified user</button>
            <button className="firsttime-choice-button" style={{backgroundColor:"#DD3333"}} onClick={() => chooseoption("affiliate")}>Become affiliate</button>
            <button className="firsttime-choice-button" style={{backgroundColor:"gray"}} onClick={() => chooseoption("browse")}>Just browsing</button>
        </div>
        </Box>
        </Modal>
  );
}

export default Firsttime;
