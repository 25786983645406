import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Back button, works by storing previous views in state.
export default function BackButton({ viewHistory, setView, setViewHistory }) {
    const goBack = () => {
      if (viewHistory.length > 0) {
        const lastView = viewHistory[viewHistory.length - 1];
        setView(lastView);
        setViewHistory(viewHistory.slice(0, -1));
      }
    };
  
    return (
    <ArrowBackIcon onClick={goBack} className="back-button" style={{display: viewHistory.length === 0 ? "none" : "block"}} />
    );
  }