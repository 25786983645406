import '../App.css';
import { withUAL } from "ual-reactjs-renderer";
import React, { useState, useEffect } from "react";
import Card from './Card'
import Feedmenu from './Feedmenu'

//The feed component on main page. It renders the feed menu with categories and a card for each solution.
function Feed({triggerfetch, setTriggerFetch, view, rewards, solutions, logo_url, sweetalert, activeUser, setView, setSolutionid, setCategory, category, accountname, categories}) {
  return (
    <div className="App">
    <Feedmenu view={view} categories={categories} category={category} setCategory={setCategory}/>
    {solutions?.map((item, index) => {
      if(item.category==category || category == "All categories"){
      return(<Card triggerfetch={triggerfetch} setTriggerFetch={setTriggerFetch} rewards={rewards} key={index} logo_url={logo_url} solutionkey={index} {...item} accountname={accountname} activeUser={activeUser} sweetalert={sweetalert} setView={setView} setSolutionid={setSolutionid}/>)
      }
    })}
    </div>
  );
}

export default withUAL(Feed);
