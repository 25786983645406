import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Affiliate from "../Universal/Affiliate";


//This is the mobile menu.
export default function TemporaryDrawer({rewards, balance, setTriggerFetch, triggerfetch, accountname, userType, logmein, logmeout, setView, solutions, setSolutionid, sweetalert, activeUser, setCategory, setOpenverifyuser, openverifyuser, handleCloseverifyuser, handleOpenverifyuser, openaddsolution, setOpenaddsolution, handleCloseaddsolution, openaffiliate, handleCloseaffiliate, handleOpenaffiliate, mysolution}) {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      <React.Fragment>
        <div onClick={toggleDrawer('right', true)}><MenuIcon /></div>
        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer('right', false)}
        >
        <div className="temporary-drawer-content">
          
        {accountname ?
          <button className="temporary-drawer-button temporary-drawer-button-log" onClick={()=> logmeout()}>
            <span>{accountname}</span>
            <span className="usertype">({userType})</span>
            <br></br>
            {balance ? <span className="usertype">Balance<br></br> {balance}</span> : <></>}

          </button>
          :
          <button className="temporary-drawer-button temporary-drawer-button-log" onClick={()=> logmein()}>
            Log in
          </button>
        }
        <hr width="70%"/>
        {userType == "affiliate" ?
        <>
        <button className="temporary-drawer-button" onClick={() => setView("collaborations")}>
          Collaborations
        </button>
        <button className="temporary-drawer-button" onClick={() => mysolution()}>
          My solution
        </button>
        <Affiliate rewards={rewards} accountname={accountname} sweetalert={sweetalert} openaffiliate={openaffiliate} handleCloseaffiliate={handleCloseaffiliate} handleOpenaffiliate={handleOpenaffiliate}/>

        </>
        :
        userType == "admin" ?
        <>
        <button className="temporary-drawer-button" onClick={() => setView("proposedsolutions")}>
          Proposed solutions
        </button>
        <button className="temporary-drawer-button" onClick={() => setView("verifyusers")}>
          Verify users
        </button>
        <button className="temporary-drawer-button" onClick={() => setView("verifiedusers")}>
          Verified users
        </button>
        <Affiliate rewards={rewards} accountname={accountname} sweetalert={sweetalert} openaffiliate={openaffiliate} handleCloseaffiliate={handleCloseaffiliate} handleOpenaffiliate={handleOpenaffiliate}/>

        </>:
        userType == "verified" ?
        <button className="temporary-drawer-button">
          Verifiedmenu
        </button>:
        userType == "unverified" ?
        <>
        <button className="temporary-drawer-button" onClick={()=> setView("addsolution")} >
          Add solution
        </button>
        <button className="temporary-drawer-button" onClick={handleOpenverifyuser}>
          Verify user
        </button>
        <Affiliate rewards={rewards} accountname={accountname} sweetalert={sweetalert} openaffiliate={openaffiliate} handleCloseaffiliate={handleCloseaffiliate} handleOpenaffiliate={handleOpenaffiliate}/>

        </>
        :
        <>
        </>
        }
        <a href="https://www.eosde.net" ><button className="temporary-drawer-button">EOSDEN Portal</button></a>
        <a href="https://web3wax.eosde.net" ><button className="temporary-drawer-button">EOSDEN WAX</button></a>

      </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
