import '../App.css';
import { withUAL } from "ual-reactjs-renderer";
import React, { useState, useEffect } from "react";
import Accordion from '../Universal/Accordion';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';

function Createquiz({editQuiz, setEditQuiz, accountname, activeUser, sweetalert, triggerfetch, setTriggerFetch}) {
  const [questions, setQuestions] = useState([""]); // One empty question by default
  const [answers, setAnswers] = useState([["", ""]]); // Two empty answers for the first question
  const [correctAnswers, setCorrectAnswers] = useState([[]]); // No correct answers selected by default
  const [quizconfig, setQuizconfig] = useState()
  const [quizid, setQuizid] = useState()
  const [verifReq, setVerifReq] = useState(true)
  const [makeGpt, setMakeGpt] = useState(false)
  const handleCloseGpt = () => setMakeGpt(false);
  const [gptLoading, setGptLoading] = useState(false)
  const [data, setData] = useState("")



  const generateWithGPt = async () => {
    if(countWords(data) > 2500){
      sweetalert("The data is too long, please keep it under 2500 words.")
      return
    }
    if(document.getElementById("maxQuestions").value > 10 || document.getElementById("maxQuestions").value < 1){
      sweetalert("Please enter a number between 1 and 10 for the number of questions.")
      return
    }
    setGptLoading(true)
    //const quizjson = await fetch("https://us-central1-eosden.cloudfunctions.net/api/generate-quiz-questions", {
      const quizjson = await fetch("https://us-central1-eosden.cloudfunctions.net/api/generate-quiz-questions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: document.getElementById("data").value,
        instructions: document.getElementById("instructions").value,
        maxQuestions: maxQuestions,
      }),
    })

    const result = await quizjson.json()
    const resultjson = JSON.parse(result?.message)

    setGptLoading(false)
    setMakeGpt(false)
    setQuestions(resultjson?.questions)
    setAnswers(resultjson?.answers)
    setCorrectAnswers(resultjson?.correct_answers)
  }

  const [maxQuestions, setMaxQuestions] = useState(5);

  const handleMaxQuestionsChange = (event) => {
    let newValue = parseInt(event.target.value, 10);

    // Check if the newValue is an integer and within the 1-10 range
    if (!isNaN(newValue) && newValue >= 1 && newValue <= 10) {
      setMaxQuestions(newValue);
    } else if (event.target.value === '') {
      // Optionally handle the empty string case, e.g., reset to default or clear the field
      setMaxQuestions('');
    }
    // If newValue is out of bounds or not an integer, don't update the value state
  };

  const clearAllQuestions = () => {
    setQuestions([""])
    setAnswers([["", ""]])
    setCorrectAnswers([[]])
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "30vw",
    bgcolor: 'white',
    borderRadius: "20px",
    border: "none",
    outline: "none",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    '@media (max-width: 768px)': {
      width: '80vw',
    },
  };

  const [formData, setFormData] = useState({
    affiliate: editQuiz ? editQuiz.affiliate : accountname,
    maxUsers: editQuiz ? editQuiz.max_users : null,
    quizDescription: editQuiz ? editQuiz.quiz_description : "",
    correctAnswersPercentage: editQuiz ? editQuiz.correct_answers_perc : "",
    tokenContract: editQuiz ? editQuiz.token_contract : "",
    quizRewards: editQuiz ? editQuiz.quiz_rewards : "",
  });


  useEffect(()  => {
    console.log(editQuiz)
        fetch(`https://eos.eosusa.io/v1/chain/get_table_rows`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            json: true,
            code: "nova3mindweb",
            table: "verifreq",
            scope: editQuiz?.affiliate,
          }),
        })
          .then((response) =>
            response.json().then((res) => {
              if(res?.rows[res?.rows?.length - 1]?.quiz_id == editQuiz?.quiz_id){
                if(res?.rows[res?.rows?.length - 1]?.verif_requirement == 1){
                  setVerifReq(true)
                }
                else if(res?.rows[res?.rows?.length - 1]?.verif_requirement == 0){
                  console.log("SETTING TO FALSE")
                  console.log(res?.rows[res?.rows?.length - 1]?.verif_requirement == 1)
                  setVerifReq(false)
                }
              }
              else{
                setVerifReq(true)
              }
            })
          )
          .catch((e) => console.log(e));
  }, [editQuiz])

  // Function to add a question
  const addQuestion = () => {
    console.log(correctAnswers)
    setQuestions([...questions, ""]);
    setAnswers([...answers, ["", ""]]);
    setCorrectAnswers([...correctAnswers, []]);
  };

  const initializeFromEditQuiz = (editQuiz) => {
    if (!editQuiz) return;

    // Set questions
    setQuestions(editQuiz.questions);

    // Set answers
    setAnswers(editQuiz.answers);

    // Transform and set correct answers
    const transformedCorrectAnswers = editQuiz.correct_answers.map((questionAnswers, qIndex) =>
        questionAnswers
            .map((answer, aIndex) => answer === 1 ? aIndex : -1)
            .filter(index => index !== -1)
    );

    setCorrectAnswers(transformedCorrectAnswers);
};

useEffect(() => {
  if (editQuiz) {
    console.log("EDIT QUIZ")
    console.log(editQuiz)
      initializeFromEditQuiz(editQuiz);
  }
}, [editQuiz]);

  useEffect(()=>{    

    const getConfig = async () => {
      await fetch(`https://eos.eosusa.io/v1/chain/get_table_rows`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          json: true,
          code: "nova3mindweb",
          table: "quizconf",
          scope: "nova3mindweb",
        }),
      })
        .then((response) =>
          response.json().then((res) => {
            setQuizconfig(res?.rows[0]);
          })
        )
        .catch((e) => console.log(e));
        await fetch(`https://eos.eosusa.io/v1/chain/get_table_rows`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            json: true,
            code: "nova3mindweb",
            table: "quiztb",
            scope: accountname,
          }),
        })
          .then((response) =>
            response.json().then((res) => {
              setQuizid(res?.rows?.length);
            })
          )
          .catch((e) => console.log(e));
    };
    getConfig()
    },[])

  // Function to remove a question
  const removeQuestion = (index) => {
    const updatedQuestions = questions.filter((_, qIndex) => qIndex !== index);
    const updatedAnswers = answers.filter((_, aIndex) => aIndex !== index);
    const updatedCorrectAnswers = correctAnswers.filter((_, cIndex) => cIndex !== index);

    setQuestions(updatedQuestions);
    setAnswers(updatedAnswers);
    setCorrectAnswers(updatedCorrectAnswers);
  };
  
  const transformCorrectAnswersFormat = () => {
    return questions.map((_, qIndex) => {
      return answers[qIndex].map((_, aIndex) => {
        return correctAnswers[qIndex].includes(aIndex) ? 1 : 0;
      });
    });
  };

    const updateQuestion = (index, value) => {
      const updatedQuestions = [...questions];
      updatedQuestions[index] = value;
      setQuestions(updatedQuestions);
    };
  
    const addAnswer = (questionIndex) => {
      const currentAnswers = answers[questionIndex];
      if (currentAnswers.length >= 5) {
        sweetalert("Can't add more than 5 answer options!");
        return;
      }
    
      const updatedAnswers = [...answers];
      updatedAnswers[questionIndex].push("");
      setAnswers(updatedAnswers);
    };
    
  
    const updateAnswer = (questionIndex, answerIndex, value) => {
      const updatedAnswers = [...answers];
      updatedAnswers[questionIndex][answerIndex] = value;
      setAnswers(updatedAnswers);
    };
  
    const toggleCorrectAnswer = (questionIndex, answerIndex) => {
      const updatedCorrectAnswers = [...correctAnswers];
      const currentCorrectAnswers = updatedCorrectAnswers[questionIndex];
      if (currentCorrectAnswers.includes(answerIndex)) {
        updatedCorrectAnswers[questionIndex] = currentCorrectAnswers.filter(aIndex => aIndex !== answerIndex);
      } else {
        updatedCorrectAnswers[questionIndex] = [...currentCorrectAnswers, answerIndex];
      }
      setCorrectAnswers(updatedCorrectAnswers);
    };

    const removeLastAnswer = (questionIndex) => {
      const updatedAnswers = [...answers];
      const currentQuestionAnswers = updatedAnswers[questionIndex];
    
      if (currentQuestionAnswers.length > 2) { // Ensure there are at least 2 answers remaining
        updatedAnswers[questionIndex] = currentQuestionAnswers.slice(0, -1); // Remove the last answer
      } else {
        sweetalert("A question must have at least 2 answers.");
      }
    
      setAnswers(updatedAnswers);
    };

    const getEosAmount = async (usdtamount) => {
      try {
        // URL to EOSIO node
        const url = `https://eos.eosusa.io/v1/chain/get_table_rows`;
        // Fetch data from EOSIO node
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            json: true,
            code: "swap.defi",
            table: "pairs",
            scope: "swap.defi",
            lower_bound: 12,
            upper_bound: 12,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        // If response is not ok, throw an error
        if (!response.ok) {
          throw Error('Network response was not ok' + response.statusText);
        }
  
        // Parse JSON data
        const data = await response.json();
        let eosamount =  (Number(data?.rows[0]?.price1_last).toFixed(4) * usdtamount * 1.01).toFixed(4)
        console.log("EOS Amount: " + eosamount)
        return eosamount
      }
      catch(error){
        console.log(error)
      }
    
    }
  
    const handleSubmit = async() => {
      const eosamounttopay = await getEosAmount(Number(quizconfig?.quiz_cost.split(" ")[0]))
      console.log(formData)
      // Handle submission logic here
      if (activeUser) {
        const transformedCorrectAnswers = transformCorrectAnswersFormat();
        if(editQuiz){
          try {
            // fee and amount for user rewards are separate actions!!!!!!!!!!!!
            const transaction = {
              actions: [
                {
                  account: "nova3mindweb",
                  name: "editquiz",
                  authorization: [
                    {
                      actor: accountname, // use account that was logged in
                      permission: "active",
                    },
                  ],
                  data: {
                    quiz_id: editQuiz.quiz_id,
                    affiliate: formData.affiliate,
                    max_users: formData.maxUsers,
                    quiz_description: formData.quizDescription,
                    correct_answers_perc: formData.correctAnswersPercentage,
                    questions: questions,
                    answers: answers,
                    correct_answers: transformedCorrectAnswers,
                    token_contract: formData.tokenContract,
                    verif_requirement: verifReq ? true : false
                  },
                }
              ],
            };
            await activeUser.signTransaction(transaction, {
              expireSeconds: 300,
              blocksBehind: 3,
              broadcast: true,
            });
  
            sweetalert("Quiz edited!")
            setTimeout(() => {
              setTriggerFetch(!triggerfetch)
            }, 1000);
            } catch (error) {
            sweetalert(error.message)
          }
        }
        else{
          console.log({                  from: accountname,
            to: "swap.defi",
            quantity: eosamounttopay + " EOS",
            memo: "swap,0,12",})
        try {
          // fee and amount for user rewards are separate actions!!!!!!!!!!!!
          const transaction = {
            actions: [
              {
                account: "eosio.token",
                name: "transfer",
                authorization: [
                  {
                    actor: accountname,
                    permission: "active",
                  },
                ],
                data: {
                  from: accountname,
                  to: "swap.defi",
                  quantity: eosamounttopay + " EOS",
                  memo: "swap,0,12",
                },
              },
              {
                account: "tethertether",
                name: "transfer",
                authorization: [
                  {
                    actor: accountname,
                    permission: "active",
                  },
                ],
                data:
                {
                  from: accountname,
                  to: "nova3mindweb",
                  quantity: quizconfig?.quiz_cost,
                  memo: "fee,0,"+accountname
                },
              },
              {
                account: "nova3mindweb",
                name: "createquiz",
                authorization: [
                  {
                    actor: accountname, // use account that was logged in
                    permission: "active",
                  },
                ],
                data: {
                  affiliate: formData.affiliate,
                  max_users: formData.maxUsers,
                  quiz_description: formData.quizDescription,
                  correct_answers_perc: formData.correctAnswersPercentage,
                  questions: questions,
                  answers: answers,
                  correct_answers: transformedCorrectAnswers,
                  token_contract: formData.tokenContract,
                  verif_requirement: verifReq
                },
              },
              {
                account: formData.tokenContract,
                name: "transfer",
                authorization: [
                  {
                    actor: accountname,
                    permission: "active",
                  },
                ],
                data:
                {
                  from: formData?.affiliate,
                  to: "nova3mindweb",
                  quantity: formData?.quizRewards,
                  memo: "rewards,0,"+accountname
                },
              },
            ],
          };
          await activeUser.signTransaction(transaction, {
            expireSeconds: 300,
            blocksBehind: 3,
            broadcast: true,
          });

          sweetalert("Quiz created!")
          setTimeout(() => {
            setTriggerFetch(!triggerfetch)
          }, 1000);
          } catch (error) {
          sweetalert(error.message)
        }
      }
      } else {
        sweetalert("Please log in.")
      }
    };

    const countWords = (text) => {
      return text.length > 0 ? text.trim().split(/\s+/).length : 0;
    };

  return (
    <div className="App">
      <Modal
        open={makeGpt}
        onClose={handleCloseGpt}
      >
        <Box sx={style}>
        <b style={{alignSelf:"flex-start"}}>Data to base the quiz on</b>
        <TextField
            name="data"
            rows={10}
            multiline
            id="data"
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(e) => setData(e.target.value)}
            placeholder="Paste all the data you want GPT to create a quiz from. Could be a medium article of your app, or text from your website."
          />
        <div className="wordCounterGPT" style={{color: countWords(data) > 2500 ? "red" : "gray"}}>{countWords(data)}/2500</div>

        <b style={{alignSelf:"flex-start"}}>Instructions (optional)</b>
        <TextField
            name="instructions"
            rows={2}
            multiline 
            id="instructions"
            variant="outlined"
            sx={{ width: "100%" }}
            placeholder="If you have any specific topics you'd like the quiz to cover, specify them here."
          />
          <b style={{alignSelf:"flex-start"}}>Number of questions preferred (1-10)</b>
          <TextField
            name="maxQuestions"
            id="maxQuestions"
            variant="outlined"
            sx={{ width: "100%" }}
            value={maxQuestions}
            onChange={handleMaxQuestionsChange}
            type="number"
            inputProps={{
              // Directly applying min, max, step for native HTML validation
              // Note: This won't prevent manual entry of invalid values but will block form submission without JS validation
              min: 1,
              max: 10,
              step: 1
            }}
          />
          <button className="modal-submit" onClick={()=>generateWithGPt()}>{gptLoading ? "Loading..." : "Generate quiz for me"}</button>
        </Box>
      </Modal>
        <div className="addsolution">
          <b style={{alignSelf:"flex-start"}}>Quiz settings</b>
          <Accordion trigger="Read about how to fill..." text={"Maximum users - enter maximum number of users you woud like to permit to complete the quiz. The number of max. users determines how many tokens each user receives as a reward. The larger the number for max users the less rewards per user. eg 10 EOS rewards / 10 max users = 1 EOS reward per user. \n\nQuiz description - enter a description for the quiz \n\n Token contract - contract of the token that is used for rewards \n\n Quiz reward - needs to be entered with the correct amount of decimals, and the correct token symbol. For example: 1.0000 EOS. \n\n Correct answers percentage - the amount of questions that the user needs to answer completely correctly, in order to receive rewards. \n\n \n\nQuestions and answers - add as many questions as you like. Each question can have up to 5 possible answers. Use the checkboxes to indicate correct answers. Several answers can be correct. \n\n While the quiz can be edited after submission, additional rewards can not be added."} />
          <b>Quiz costs: {quizconfig?.quiz_cost} (paid in EOS)</b>
          <TextField
            name="affiliate"
            id="affiliate"
            label="Affiliate name"
            disabled="true"
            variant="outlined"
            sx={{ width: "100%" }}
            value={formData.affiliate}
            onChange={(e) => setFormData({ ...formData, affiliate: e.target.value })}
          />
          <TextField
            name="maxUsers"
            id="maxUsers"
            label="Maximum users"
            variant="outlined"
            sx={{ width: "100%" }}
            type="number"
            value={formData.maxUsers}
            onChange={(e) => setFormData({ ...formData, maxUsers: e.target.value })}
          />
          <div style={{display:"flex", alignItems:"center"}}>
          <Checkbox
            name={`verif_requirement`}
            checked={verifReq}
            onChange={() => setVerifReq(!verifReq)}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 },
            color: "#DD3333",
            '&.Mui-checked': {
              color: "#DD3333",
            }, }}
          />
          <span>Let only verified users take the quiz?</span>
          </div>
          <TextField
            name="quizDescription"
            id="quizDescription"
            label="Quiz description"
            variant="outlined"
            sx={{ width: "100%" }}
            multiline
            rows={4}
            value={formData.quizDescription}
            onChange={(e) => setFormData({ ...formData, quizDescription: e.target.value })}
          />
          {editQuiz ? null :
          <TextField
            name="tokenContract"
            id="tokenContract"
            label="Token contract"
            variant="outlined"
            sx={{ width: "100%" }}
            value={formData.tokenContract}
            onChange={(e) => setFormData({ ...formData, tokenContract: e.target.value })}
          />
          }
          <TextField
            name="correctAnswersPercentage"
            id="correctAnswersPercentage"
            label="Correct answers percentage"
            variant="outlined"
            type="number"
            sx={{ width: "100%" }}
            value={formData.correctAnswersPercentage}
            onChange={(e) => setFormData({ ...formData, correctAnswersPercentage: e.target.value })}
          />
          {!editQuiz ? <TextField
            name="quizRewards"
            id="quizRewards"
            label="Quiz reward (correct symbol and decimals)"
            variant="outlined"
            sx={{ width: "100%" }}
            value={formData.quizReward}
            onChange={(e) => setFormData({ ...formData, quizRewards: e.target.value })}
          /> : null}
          <hr className="solution-splitter"/>
        <div style={{width:"100%", display:"flex", justifyContent:"space-between", flexWrap:"wrap"}}>
          <b style={{alignSelf:"flex-start"}}>Add questions</b>
          <div style={{marginLeft:"auto", gap:"10px", display:"flex"}}>
          <button className="quizbuttonsmall anim-bg-gradient" onClick={()=>setMakeGpt(true)}><b>Generate quiz with GPT-4</b></button>
          <button className="quizbuttonsmall" onClick={clearAllQuestions}>Clear all questions</button>
          <button className="quizbuttonsmall" onClick={addQuestion}>Add question</button>
          </div>

        </div>
        {questions.map((question, qIndex) => (
          <div style={{width:"100%"}} key={qIndex}>
            <TextField
              value={question}
              onChange={(e) => updateQuestion(qIndex, e.target.value)}
              label={`Question ${qIndex + 1}`}
              variant="outlined"
              sx={{ width: "100%", marginY: 1, marginBottom:"15px" }}
            />
            {answers[qIndex].map((answer, aIndex) => (
              <div key={aIndex} style={{ display: 'flex', flexDirection:"row", alignItems: 'center', marginBottom: "15px" }}>
                <Checkbox
                  name={`correct-answer-${qIndex}`}
                  checked={correctAnswers[qIndex].includes(aIndex)}
                  onChange={() => toggleCorrectAnswer(qIndex, aIndex)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 32 },
                  color: "#DD3333",
                  '&.Mui-checked': {
                    color: "#DD3333",
                  }, }}
                />
                <TextField
                  value={answer}
                  onChange={(e) => updateAnswer(qIndex, aIndex, e.target.value)}
                  label={`Answer ${aIndex + 1}`}
                  variant="outlined"
                  sx={{width:"100%"}}
                />
              </div>
            ))}
            <div style={{display:"flex", gap:"10px", marginTop:"15px"}}>
              <button className="quizbuttonsmall" onClick={() => addAnswer(qIndex)}>Add Answer</button>
              <button className="quizbuttonsmall" onClick={() => removeLastAnswer(qIndex)}>Remove Last Answer</button>
              {questions.length > 1 && ( // Show remove button only if there is more than one question
                <button className="quizbuttonsmall" onClick={() => removeQuestion(qIndex)}>Remove Question</button>
              )}
            </div>            <hr className="solution-splitter"/>
          </div>
        ))}
        <button className="modal-submit" onClick={handleSubmit}>Submit quiz</button>
        </div>
    </div>
  );
}

export default withUAL(Createquiz);
