import '../App.css';
import React, { useState, useEffect } from "react";

function Verifiedusers({endpoint}) {
  const [transactionData, setTransactionData] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${endpoint}/v1/chain/get_table_rows`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            json: true,
            code: "nova3mindweb",
            table: "verifieduser",
            scope: "nova3mindweb",
            limit:200
          }),
        });

        const result = await response.json();
        setTransactionData(result?.rows);
      } catch (error) {
        console.error('Error fetching users and transaction data:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className="App">
      <div className="verified-users">
      {transactionData?.length > 0 ? (
        transactionData?.map((item, index) => (
          <div className="verified-users-user" key={index}>
            <b>{item?.user}</b>
            {item?.socials?.map((v, i)=>{
              if(v){
              return(<div style={{ overflowWrap: "break-word", wordBreak: "break-word" }}>{v?.key + ": " + v?.value}</div>)
              }
            })}
          </div>
        ))
      ) : (
        <p>Loading transaction data...</p>
      )}
      </div>
    </div>
  );
}

export default Verifiedusers