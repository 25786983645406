import React, { useState, useEffect } from "react";
import '../App.css';
import Verifyuser from "../Unverified/Verifyuser";
import '../../fonts.css';
import TemporaryDrawer from "./TemporaryDrawer";
import Affiliate from "../Universal/Affiliate";

const Navbar = ({balance, setTriggerFetch, triggerfetch, accountname, userType, logmein, logmeout, setView, solutions, setSolutionid, sweetalert, activeUser, setCategory, setOpenverifyuser, openverifyuser, handleCloseverifyuser, handleOpenverifyuser, openaddsolution, setOpenaddsolution, handleCloseaddsolution, openaffiliate, handleCloseaffiliate, handleOpenaffiliate}) => {


  const mysolution = () =>{
    console.log(solutions)
    const matchingIndex = solutions?.findIndex(
      (solution) => solution.affiliate === accountname
    );
    setSolutionid(matchingIndex)
    setView("solution")
  }
  const goHome = () =>{
    window.history.replaceState({}, document.title, "/");
    setView("feed")
    setCategory("All categories")
    setTriggerFetch(triggerfetch+1)
  }
  return (
    <div className="navbar">
      <Verifyuser setTriggerFetch={setTriggerFetch} triggerfetch={triggerfetch} handleOpenverifyuser={handleOpenverifyuser} handleCloseverifyuser={handleCloseverifyuser} openverifyuser={openverifyuser} sweetalert={sweetalert} accountname={accountname} activeUser={activeUser}/>
      <div className="brand" onClick={()=>goHome()}><span className="brand-eos">eos</span>den</div>
      <div className="temporary-drawer"><TemporaryDrawer openaffiliate={openaffiliate} handleCloseaffiliate={handleCloseaffiliate} handleOpenaffiliate={handleOpenaffiliate} balance={balance} mysolution ={mysolution} handleOpenverifyuser ={handleOpenverifyuser} logmein={logmein} logmeout={logmeout} accountname={accountname} activeUser={activeUser} userType={userType} setView={setView} solutions={solutions} setSolutionid={setSolutionid} sweetalert={sweetalert}/></div>
      <div className="navbar-buttons">
        <a href="https://web3wax.eosde.net"><button className="menubutton">EOSDEN WAX</button></a>
        <a href="https://www.eosde.net"><button className="menubutton">EOSDEN Portal</button></a>
        {userType == "affiliate" ?
        <>
        <Affiliate accountname={accountname} sweetalert={sweetalert} openaffiliate={openaffiliate} handleCloseaffiliate={handleCloseaffiliate} handleOpenaffiliate={handleOpenaffiliate}/>
        <button className="menubutton" onClick={() => setView("collaborations")}>
          Collaborations
        </button>
        <button className="menubutton" onClick={() => mysolution()}>
          My solution
        </button>
        </>
        :
        userType == "admin" ?
        <>
        <Affiliate accountname={accountname} sweetalert={sweetalert} openaffiliate={openaffiliate} handleCloseaffiliate={handleCloseaffiliate} handleOpenaffiliate={handleOpenaffiliate}/>
        <button className="menubutton" onClick={() => setView("proposedsolutions")}>
          Proposed solutions
        </button>
        <button className="menubutton" onClick={() => setView("verifyusers")}>
          Verify users
        </button>
        <button className="menubutton" onClick={() => setView("verifiedusers")}>
          Verified users
        </button>
        </>:
        userType == "verified" ?
        <>
        <Affiliate accountname={accountname} sweetalert={sweetalert} openaffiliate={openaffiliate} handleCloseaffiliate={handleCloseaffiliate} handleOpenaffiliate={handleOpenaffiliate}/>
        <button onClick={()=> setView("addsolution")} className="menubutton">
          Add solution
        </button>
        </>:
        userType == "unverified" ?
        <>
        <button onClick={()=> setView("addsolution")} className="menubutton">
          Add solution
        </button>
        <button onClick={handleOpenverifyuser} className="menubutton">
          Verify user
        </button></>
        :
        <>
        </>
        }

        {accountname ?
          <>
            <button className="username">
              <span>{accountname}</span>
              <span className="usertype">({userType})</span>
            </button>
            <button onClick={()=> logmeout()} className="logbutton">
              <span>Logout</span>
            </button>
          </>
          :
          <button onClick={()=> logmein()} className="logbutton">
            Log in
          </button>
        }
      </div>
    </div>
  );
};

export default Navbar;