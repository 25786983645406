import '../App.css';
import { withUAL } from "ual-reactjs-renderer";
import React, { useState, useEffect } from "react";
import { deserializeTransaction } from '../Helpers/deserializer';
import { sendEncryptedEmail } from '../Helpers/sendEmail';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function Verifyusers({accountname, sweetalert, activeUser, endpoint, triggerfetch, setTriggerFetch}) {
  const style = {
    width:"50vw",
    '@media (max-width: 768px)': {
      width: '90vw',
    },
  };
  const [transactionData, setTransactionData] = useState(null);

  const submitExecute = async (user_data) =>{
    console.log(user_data)
    if (activeUser) {
      try {
        const transaction = {
          actions: [
            {
              account: "eosio.msig",
              name: "exec",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                proposer: "nova3mindweb",
                proposal_name: user_data?.user,
                executer:accountname
              },
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });
        const socials = user_data?.socials;
        let encryptedemail;

        for (const social of socials) {
          if (social.key === 'email') {
            encryptedemail = social.value;
            break;
          }
        }
        console.log(encryptedemail)
        sendEncryptedEmail(encryptedemail, "account_verified")
        sweetalert("Account verified.")
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
       } catch (error) {
        sweetalert(error.message)
      }
    } else {
      sweetalert("Please log in.")
    }
  }

  const submitApprove = async (proposal_name) =>{
    if (activeUser) {
      console.log({actor:accountname, permission:"active"})
      console.log(proposal_name)
      try {
        const transaction = {
          actions: [
            {
              account: "eosio.msig",
              name: "approve",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                proposer: "nova3mindweb",
                proposal_name: proposal_name,
                level:{actor:accountname, permission:"active"}
              },
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
        } catch (error) {
        sweetalert(error.message)
      }
    } else {
      sweetalert("Please log in.")
    }
  }

  useEffect(() => {
    const fetchTransactionData = async (packedTransaction) => {
      const deserializedTransaction = await deserializeTransaction(packedTransaction)
      return deserializedTransaction;
    };

    const fetchUsers = async () => {
      try {
        const response = await fetch(`${endpoint}/v1/chain/get_table_rows`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            json: true,
            code: "eosio.msig",
            table: "proposal",
            scope: "nova3mindweb",
            limit:1000
          }),
        });

        const result = await response.json();
        const rowsWithTransactionData = await Promise.all(
          result.rows.map(async (row) => {
            const deserializedTransaction = await fetchTransactionData(row.packed_transaction);
            return { ...row, deserializedTransaction };
          })
        );
        console.log(rowsWithTransactionData)
        setTransactionData(rowsWithTransactionData);
      } catch (error) {
        console.error('Error fetching users and transaction data:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className="App">
      <div className="verifyusers-area">
        {transactionData?.length > 0 ? (
          <TableContainer>
            <Table sx={style}>
              <TableHead>
                <TableRow>
                  <TableCell><b>User</b></TableCell>
                  <TableCell align="center"><b>Approve</b></TableCell>
                  <TableCell align="center"><b>Execute</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionData?.map((item, index) => {
                  if (item?.deserializedTransaction?.actions[0]?.data?.user) {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                        <a target="blank" href={"https://bloks.io/msig/nova3mindweb/" + item.deserializedTransaction.actions[0].data.user}>{item?.deserializedTransaction?.actions[0]?.data?.user}</a>
                        </TableCell>
                        <TableCell align="center">
                          <button
                            className="verifyuser-button"
                            onClick={() =>
                              submitApprove(
                                item?.deserializedTransaction?.actions[0]?.data.user
                              )
                            }
                          >
                            Approve
                          </button>
                        </TableCell>
                        <TableCell align="center">
                          <button
                            className="verifyuser-button"
                            onClick={() =>
                              submitExecute(
                                item?.deserializedTransaction?.actions[0]?.data
                              )
                            }
                          >
                            Execute
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <p>Loading transaction data...</p>
        )}
      </div>
    </div>
  );
}

export default withUAL(Verifyusers);
