import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDnzJL_QktB7esKULdlZeVV3AlV3JA9Dxo",
    authDomain: "eosden.firebaseapp.com",
    projectId: "eosden",
    storageBucket: "eosden.appspot.com",
    messagingSenderId: "733048714760",
    appId: "1:733048714760:web:a2b25160eebe9abc2ec3bd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the storage service, which is used to create references in your storage bucket
// Create a root reference
const storage = getStorage();

const FileUpload = ({ setLogourl }) => {
  const [logo, setLogo] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0]) {
      setLogo(acceptedFiles[0]);
      handleUpload(acceptedFiles[0])
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpload = (logo) => {
    if (logo) {
      const storageRef = ref(storage, `logos/${logo.name}`);
      const uploadTask = uploadBytesResumable(storageRef, logo);
      console.log("Uploading")
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Progress updates
        },
        (error) => {
          console.log(error)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log('File available at', url);
            setLogourl(url);
          });
        },
      );
    }
  };

  return (
    <div className="upload-logo-area">
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'drag-active' : ''}`}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here...</p>
        ) : logo ? (
          <p>{logo.name}</p>
        ) : (
          <p>Drag 'n' drop a file here, or click to select a file</p>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
