import '../App.css';
import { withUAL } from "ual-reactjs-renderer";
import React, { useState, useEffect } from "react";
import { deserializeTransaction } from '../Helpers/deserializer';
import { sendEmail } from '../Helpers/sendEmail';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function Proposedsolutions({ sweetalert, activeUser, endpoint, accountname, triggerfetch, setTriggerFetch}) {
  
  const style = {
    width:"50vw",
    '@media (max-width: 768px)': {
      width: '90vw',
    },
  };

  const [transactionData, setTransactionData] = useState(null);

  const submitExecute = async (proposal_name) =>{
    //function to find the email of the proposal maker
    function findEmailByProposalName(proposals, proposalName) {
      const proposal = proposals.find((p) => p.proposal_name === proposalName);
      if (proposal) {
        const actions = proposal.deserializedTransaction.actions;
        if (actions) {
          const action = actions.find((action) => action.name === "manualverify" || action.name === "addsolution");
          if (action && action.data) {
            const socials = action.data.socials;
            const emailObj = socials.find((social) => social.key === "email");
            if (emailObj) {
              return emailObj.value;
            }
          }
        }
      }
      return null;
    }
    //find the email of the proposal maker
    const email = findEmailByProposalName(transactionData, proposal_name)
    if (activeUser) {
      try {
        const transaction = {
          actions: [
            {
              account: "eosio.msig",
              name: "exec",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                proposer: "nova3mindweb",
                proposal_name: proposal_name,
                executer:accountname
              },
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });
        sendEmail(email, "solution_added")
        sweetalert("Solution has been added.")
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
        } catch (error) {
        sweetalert(error.message)
      }
    } else {
      sweetalert("Please log in.")
    }
  }

  const submitApprove = async (proposal_name) =>{
    if (activeUser) {
      try {
        const transaction = {
          actions: [
            {
              account: "eosio.msig",
              name: "approve",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                proposer: "nova3mindweb",
                proposal_name: proposal_name,
                level:{actor:accountname, permission:"active"}
              },
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });
        sweetalert("You have approved the solution.")
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
       } catch (error) {
        sweetalert(error.message)
      }
    } else {
      sweetalert("Please log in.")
    }
  }

  useEffect(() => {
    const fetchTransactionData = async (packedTransaction) => {
      const deserializedTransaction = await deserializeTransaction(packedTransaction);
      return deserializedTransaction;
    };

    const fetchSolutions = async () => {
      try {
        const response = await fetch(`${endpoint}/v1/chain/get_table_rows`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            json: true,
            code: "eosio.msig",
            table: "proposal",
            scope: "nova3mindweb",
            limit: 1000,
          }),
        });

        const result = await response.json();
        const rowsWithTransactionData = await Promise.all(
          result.rows.map(async (row) => {
            const deserializedTransaction = await fetchTransactionData(row.packed_transaction);
            return { ...row, deserializedTransaction };
          })
        );
        setTransactionData(rowsWithTransactionData);
      } catch (error) {
        console.error('Error fetching users and transaction data:', error);
      }
    };

    fetchSolutions();
  }, [triggerfetch]);
  return (
    <div className="App">
      <div className="verifyusers-area">
      {transactionData?.length > 0 ? (
        <TableContainer>
          <Table sx={style}>
            <TableHead>
              <TableRow>
                <TableCell><b>Affiliate</b></TableCell>
                <TableCell align="right"><b>Actions</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionData.map((item, index) => {
                if (item?.deserializedTransaction?.actions[0]?.data?.affiliate) {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <a target="blank" href={"https://bloks.io/msig/nova3mindweb/" + item.deserializedTransaction.actions[0].data.affiliate}>{item.deserializedTransaction.actions[0].data.affiliate}</a>
                      </TableCell>
                      <TableCell align="right">
                        <button
                          className="verifyuser-button"
                          onClick={() =>
                            submitApprove(item.deserializedTransaction.actions[0].data.affiliate)
                          }
                        >
                          Approve
                        </button>
                        <button
                          className="verifyuser-button"
                          onClick={() =>
                            submitExecute(item.deserializedTransaction.actions[0].data.affiliate)
                          }
                        >
                          Execute
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <p>Loading transaction data...</p>
      )}
      </div>
    </div>
  );
}

export default withUAL(Proposedsolutions);
