//Calls our api with email and email type. There's two endpoints, one for regular emails, one for encrypted emails (for affiliates and users respectively).
export const sendEmail = async(email, email_type) => {
    try {
    const response = await fetch('https://eosden.herokuapp.com/send-email', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, email_type }),
        });
  
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Email sent successfully:', data);
    } catch (error) {
      console.error('Failed to send email:', error);
    }
  }

  export const sendEncryptedEmail = async(email, email_type) => {
    try {
    console.log("encrypted email:" + email)
    const response = await fetch('https://eosden.herokuapp.com/send-encrypted-email', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, email_type }),
        });
  
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Email sent successfully:', data);
    } catch (error) {
      console.error('Failed to send email:', error);
    }
  }