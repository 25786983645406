import '../App.css';
import { withUAL } from "ual-reactjs-renderer";
import React, { useState, useEffect } from "react";
import Accordion from '../Universal/Accordion'
import SixStarRating from '../Universal/Starratings'
import Chat from './Chat';
import { sendEmail } from '../Helpers/sendEmail';

function Collaborations({ rewards, collaborationkey, sweetalert, activeUser, setView, setSolutionid, accountname, collaborations, triggerfetch, setTriggerFetch,}) {
  const socials = collaborations[collaborationkey].socials;
  const emailObj = socials.find((social) => social.key === "email");
  const email = emailObj ? emailObj.value : null;
  
  const rateAffiliate = async (rating) => {
    if (activeUser) {
      try {
        const transaction = {
          actions: [
            {
              account: "nova3mindweb",
              name: "rate",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                user: accountname,
                affiliate:collaborations[collaborationkey].affiliate,
                rating: rating
              },
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });
        sweetalert("Rating successful.")
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
        } catch (error) {
        sweetalert(error.message)
      }
    } else {
      sweetalert("Please log in.")
    }
  }

  const endcollaboration = async () => {
    console.log(email)
    console.log(collaborations[collaborationkey])
    if (activeUser) {
      try {
        const transaction = {
          actions: [
            {
              account: "nova3mindweb",
              name: "endcollab",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                affiliate: accountname,
                collaborate_with: collaborations[collaborationkey].affiliate,
              },
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });
        sendEmail(email, "collaboration_ended")
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
        } catch (error) {
        sweetalert(error.message)
      }
    } else {
      sweetalert("Please log in.")
    }
  }
  const acceptcollaboration = async () => {
    if (activeUser) {
      try {
        const transaction = {
          actions: [
            {
              account: "nova3mindweb",
              name: "collaborate",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                affiliate: accountname,
                collaborate_with: collaborations[collaborationkey].affiliate,
              },
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });
        sendEmail(email, "collaboration_success")
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
        } catch (error) {
        sweetalert(error.message)
      }
    } else {
      sweetalert("Please log in.")
    }
  }

  const handleRatingChange = (newRating) => {
    rateAffiliate(newRating)
  };

  const solutionpage = () =>{
    setSolutionid(collaborations[collaborationkey].originalIndex) //TODOOOO
    setView("solution")
  }

  const get_code_type = (code_type_number) => {
    const code_types = [{type:"Open-source"}, {type:"Partly open-source"}, {type:"Closed source"}]
    return(code_types[code_type_number].type)
  }

  const [openchat, setOpenchat] = useState(false);
  const handleOpenchat= () => setOpenchat(true);
  const handleClosechat = () => setOpenchat(false);

  const getCodeTypeColor = (code_type) => {
    switch (code_type) {
      case "Open-source":
        return "#88DD33";
      case "Partly open-source":
        return "#c8b937";
      case "Closed source":
        return "DD3333";
      default:
        return "black";
    }
  };

      //Handles urls in string, makes them clickable and to open in new tab.
      const URL_REGEX = /(https?:\/\/|ftp:\/\/|www\.)[^\s]+(?:\.[^\s]+)*(?!\.)(?:\/[^\s]*)?/g;
      const getUrl = (part) => { 
      return(part.startsWith('http://') || part.startsWith('https://') || part.startsWith('ftp://')? 
      part : 'https://' + part)
      }
      const renderText = txt =>
      txt
        .split(" ")
        .map(part =>
          URL_REGEX.test(part) ? <a target="_blank" href={getUrl(part)}>{part} </a> : part + " "
        );
  

  return (
      <div className="card">
      <Chat socialData = {collaborations[collaborationkey].socials} openchat={openchat} handleClosechat={handleClosechat}/>
      <div className="card-top">
        <div className="card-logo-name-category">
          <img src={collaborations[collaborationkey].logo_url} alt="Logo" className="card-logo" />
          <div className="card-name-category">
            <div className="card-name">{collaborations[collaborationkey].solution_name}</div>
            <div className="card-category">{collaborations[collaborationkey].category}</div>
          </div>
        </div>
        <div className="card-buttons">
          <button
            className="card-source-outline"
            style={{
              borderColor: getCodeTypeColor(get_code_type(collaborations[collaborationkey].code_type)),
              color: getCodeTypeColor(get_code_type(collaborations[collaborationkey].code_type))
            }}
          >
            {get_code_type(collaborations[collaborationkey].code_type)}
          </button>
        </div>
      </div>
      <SixStarRating rewards={rewards} rating={Number(collaborations[collaborationkey].total_ratings)/Number(collaborations[collaborationkey].nr_of_voters)} onChange={handleRatingChange} /> 
      <div className="card-description">{renderText(collaborations[collaborationkey].description)}</div>
      <div className="card-bottom">
      <button className="card-button-solution" onClick={handleOpenchat}>
          Chat
        </button>
        <button className="card-button-solution" onClick={() => solutionpage()}>
          Open solution
        </button>
        {collaborations[collaborationkey].accepted_by_both == 1 ?
        <button className="card-button-solution" onClick={() => endcollaboration()}>
          End collaboration
        </button>
        : <></>}
        {collaborations[collaborationkey].accepted == 0 && collaborations[collaborationkey].accepted_by_both == 0 ?
        <button className="card-button-solution" onClick={() => acceptcollaboration()}>
          Accept collaboration
        </button>
        : <></>}
        {collaborations[collaborationkey].accepted == 1 && collaborations[collaborationkey].accepted_by_both ==0 ?
        <button className="card-source-outline">
          Offer pending
        </button>
        : <></>}
      </div>
      </div>
  );
}

export default withUAL(Collaborations);
