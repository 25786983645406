import React from 'react';
import '../App.css';
import Accordion from '../Universal/Accordion'
import { useState, useEffect } from "react";
import SixStarRating from '../Universal/Starratings'

//This is the card in the main feed/homepage. It's child of Feed.js.
const Card = ({ triggerfetch, setTriggerFetch, rewards, solutionkey, affiliate, logo_url, description, solution_name, code_type,  category, total_ratings, nr_of_voters, accountname, activeUser, sweetalert, setView, setSolutionid}) => {
  
  //Description is set to 100 words max, then it's cut off.
  const [isExpanded, setIsExpanded] = useState(false);
  const [displayText, setDisplayText] = useState(description.slice(0, 100));

  const isDescriptionLong = description.length > 100;

  //useEffect to handle descriptions
  useEffect(() => {
    if (isExpanded) {
      setDisplayText(description);
    } else {
      setDisplayText(description.slice(0, 100)  + "...");
    }
  }, [isExpanded, description]);
  
  const handleExpandClick = () => {
    if (!isExpanded) {
      setDisplayText(description);
    } else {
      setDisplayText(description.slice(0, 100) + "...");
    }
    setIsExpanded(!isExpanded);
  };

  const get_code_type = (code_type_number) => {
    const code_types = [{type:"Open-source"}, {type:"Partly open-source"}, {type:"Closed source"}]
    return(code_types[code_type_number].type)
  }

  const getCodeTypeColor = (code_type) => {
    switch (code_type) {
      case "Open-source":
        return "#88DD33";
      case "Partly open-source":
        return "#c8b937";
      case "Closed source":
        return "DD3333";
      default:
        return "black";
    }
  };

    const renderTextWithClickableLinks = (text) => {
      const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
      const parts = text.split(urlRegex);
      return parts.map((part, index) => {
        if (part.match(urlRegex)) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
            >
              {part}
            </a>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      });
    }

    const URL_REGEX = /((https?:\/\/|ftp:\/\/|www\.)[^\s,.;?!:"'<>]+(?:\.[^\s,.;?!:"'<>]+)*(?:\/[^\s,.;?!:"'<>]*)?)/g;

    const renderPart = (part, index) => {
      // Check if part starts with http, https, or ftp and does not have preceding characters
      if (/^(https?:\/\/|ftp:\/\/)/.test(part)) {
        return <a key={index} target="_blank" rel="noopener noreferrer" href={part}>{part} </a>;
      } else {
        // Attempt to isolate a URL if preceded by non-space characters (e.g., "mindmap.https://www...")
        const splitPart = part.split(/(https?:\/\/|ftp:\/\/)/);
        if (splitPart.length > 1) {
          return (
            <React.Fragment key={index}>
              {splitPart[0]}
              <a target="_blank" rel="noopener noreferrer" href={splitPart[1] + splitPart[2]}>{splitPart[1] + splitPart[2]}</a> 
            </React.Fragment>
          );
        }
        // Return part as is if no URL is detected
        return <span key={index}>{part} </span>;
      }
    };
    
    const renderText = (txt) => {
      // Split text by spaces to get individual parts
      return txt.split(/\s+/).map((part, index) => renderPart(part, index));
    };

  const rateAffiliate = async (rating) => {
    if (activeUser) {
      try {
        const transaction = {
          actions: [
            {
              account: "nova3mindweb",
              name: "rate",
              authorization: [
                {
                  actor: accountname, // use account that was logged in
                  permission: "active",
                },
              ],
              data: {
                user: accountname,
                affiliate:affiliate,
                rating: rating
              },
            },
          ],
        };
        await activeUser.signTransaction(transaction, {
          expireSeconds: 300,
          blocksBehind: 3,
          broadcast: true,
        });
        setTimeout(() => {
          setTriggerFetch(!triggerfetch)
        }, 1000);
        sweetalert("Rating successful.")

      } catch (error) {
        sweetalert(error.message)
      }
    } else {
      sweetalert("Please log in.")
    }
  }
  
  const solutionpage = () =>{
    setSolutionid(solutionkey)
    setView("solution")
  }

  const handleRatingChange = (newRating) => {
    rateAffiliate(newRating)
  };
  
  return (
    <div className="card">
      <div className="card-top">
        <div className="card-logo-name-category">
          <img onError={(e)=>{e.target.onerror = null; e.target.src="placeholder.png"}} src={logo_url} alt="Logo" className="card-logo" />
          <div className="card-name-category">
            <div className="card-name">{solution_name}</div>
            <div className="card-category">{category}</div>
          </div>
        </div>
        <div className="card-buttons">
          <button className="card-rank">Rank #{solutionkey + 1}</button>
          <button
            className="card-source-outline"
            style={{
              borderColor: getCodeTypeColor(get_code_type(code_type)),
              color: getCodeTypeColor(get_code_type(code_type))
            }}
          >
            {get_code_type(code_type)}
          </button>
        </div>
      </div>
      <SixStarRating rewards={rewards} rating={Number(total_ratings)/Number(nr_of_voters)} onChange={handleRatingChange} />

      <div className="card-description">{renderText(displayText)}</div>
      <div className="card-bottom">
      {isDescriptionLong && (
          <button className="card-button-description" onClick={handleExpandClick}>
            {isExpanded ? 'Collapse description' : 'Expand description'}
          </button>
        )}
        <button className="card-button-solution" onClick={() => solutionpage()}>
          Open solution
        </button>
      </div>
    </div>
  );
};

export default Card;